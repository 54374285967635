.insects {
  border: none;
  padding: 5px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-color: unset;
  height: 40px;
  width: 40px;
  z-index: 999;

}

.ladybug {
  background-image: url("./Ladybug.png");
}

.grasshopper{
  background-image: url("./Grasshopper.png");
}

.pillBug{
  background-image: url("./PillBug.png");
}

.scarab{
  background-image: url("./Scarab.png");
}

.aphid{
  background-image: url("./Aphid.png");
}

