@import "_labVariables.scss";
html {
  font-family: $font-family-base;
  font-weight: 100;
  font-size: $font-size-initial;
}

body {
  overflow-x: hidden;
  color: $text-color;
  background: $white;
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
}
.admin-nav {
iframe {
  min-width: 500px!important;
    min-height: 300px!important;
}
}


html,
body,
#app {
  height: 100%;
}

p {
  margin-bottom: 0;
}

a,
.dropdown-item,
.btn {
  cursor: pointer;
  transition: color 0.1s ease-in-out, background-color 0.1s ease-in-out,
    border-color 0.1s ease-in-out;
  font-family: $font-family-base;
}

a:hover {
  color: inherit;
  text-decoration: none;
}

th {
  font-size: $font-size-base;
  font-weight: $font-weight-normal;
  color: $base-dark-gray;
}

td {
  color: $default;
}

.table td {
  vertical-align: middle;
}

ul ul,
ol ul {
  list-style: none;
}

ul,
ol {
  list-style: none;
  padding-left: 0;
}

.divider:after,
.divider:before {
  content: "";
  flex: 1;
  height: 1px;
  background: #eee;
}

.btn-lg,
.btn-group-lg > .btn {
  padding: 14px 31px;
  font-size: 1.25rem;
  border-radius: 1px;
  background: $extend-color;
  font-weight: 400;
}

.h-custom {
  height: calc(100% - 73px);
}

@media (max-width: 450px) {
  .h-custom {
    height: 100%;
  }
}

.login-form {
  background: #fff;
  padding: 10px 5%;
  border-radius: var(--border-radius);
}

.login-form h1 {
  font-size: 30px !important;
}

.reset-password-section {
  background-color: #fff;
  padding: 15px 5%;
  border-radius: var(--border-radius);
}

.login-bg {
  width: 80%;
  margin-top: 3rem;
}

.carousel-indicators {
  display: none;
}

.center {
  text-align: center;
}

.carousel-control-prev,
.carousel-control-next {
  display: none;
}

.pull-right {
  text-align: right;
  float: right;
}

.remember {
  float: right !important;
  right: 0px;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 3rem !important;
}

.form-control {
  background: #ffffff;
  border: 1px solid $extend-color;
  border-radius: 1px;
}

.form-check-label {
  color: #aeaeae;
  font-size: 12px;
  position: relative;
  top: -5px;
  right: 30px;
}

.tf15 {
  font-size: 15px !important;
  font-weight: bold;
}

.mt-5r {
  margin-top: 5rem;
}
.mt-4r {
  margin-top: 4rem;
}

.mt-3r {
  margin-top: 3rem;
}
.mt-3hr {
  margin-top: 3.5rem;
}

label {
  font-size: 0.8rem;
  color: $text-color-dark;
}

.form-control {
  font-size: 1rem;
}

.btn-lg,
.btn-group-lg > .btn,
.btn-group-lg > .btn {
  font-size: 1rem;
}

.video-lg {
  // width: 180%;
  width: 540px;
}

.numeric-value-holder {
  margin-top: 50px;
  margin-bottom: 50px;
  width: 100%;
  text-align: center;
}

.numeric-values {
  background-color: #c4c4c4;
  color: $text-color-dark;
  padding-top: 20px;
  padding-left: 100px;
  padding-right: 100px;
  padding-bottom: 20px;
}

.space-pm-0 {
  padding: 0px;
  margin: 0px;
}

.space-py-5 {
  padding-top: 5px;
  padding-bottom: 5px;
}

.space-py-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.space-px-10 {
  padding-left: 10px;
  padding-right: 10px;
}

.space-my-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.space-mx-10 {
  margin-left: 10px;
  margin-right: 10px;
}

.space-py-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.space-pt-20 {
  padding-top: 20px;
}

.space-px-20 {
  padding-left: 20px;
  padding-right: 20px;
}

.space-my-20 {
  margin-top: 20px;
  margin-bottom: 20px;
  display: block;
}

.space-mx-20 {
  margin-left: 20px;
  margin-right: 20px;
}

.space-my-40 {
  margin-top: 40px;
  margin-bottom: 40px;
}

.space-mx-40 {
  margin-left: 40px;
  margin-right: 40px;
}

.space-px-40 {
  padding-left: 40px;
  padding-right: 40px;
}

.space-py-40 {
  padding-top: 40px;
  padding-bottom: 40px;
}

.bg-orange {
  background-color: #fff8e8;
}

.bg-blue {
  background-color: #e1f0ff;
}

.text-primary {
  color: #db2c66;
}

.text-success {
  color: #09bc98;
}

.button-center {
  text-align: center;
  display: flex;
  justify-content: center;
}

/* imag overlayer */
.overlayer-center {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0%;
  overflow: hidden;
}

.overlayer-bg-dark {
  background-color: #000000;
  opacity: 0.8;
  transition: top 200ms ease 0s;
}

.overlayer-space-20 {
  padding: 20px;
}

.w-80 {
  width: 80%;
}

.w-81 {
  width: 81%;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.hr-white {
  background-color: #fff;
  width: 50%;
  margin-top: 5px;
  margin-bottom: 5px;
}

/* start -> manage 2 layers on normal and hover state */
.layer-1 {
  visibility: visible;
}

.layer-2 {
  visibility: hidden;
}

.course-card :hover {
  .layer-1 {
    visibility: hidden;
    transition-duration: 500ms;
    transition-property: all;
    transition-timing-function: ease;
  }

  .layer-2 {
    visibility: visible;
    transition-delay: 500ms;
    transition-property: all;
    transition-timing-function: ease;
  }
}

/* End -> manage 2 layers on normal and hover state */

.orderd-list {
  .list-group {
    list-style: decimal inside;
  }

  .list-group-item {
    display: list-item;
  }
}

.rounded-image {
  width: 150px;
  border-radius: 50%;
}

.explore-measure-img-container {
  display: inline-block;
  width: 540px;
  height: 400px;
  margin: 0 auto;
  position: relative;
  border-radius: 10px;
  max-width: 100%;
}

.explore-measure-img-canvas {
  position: absolute;
  z-index: 20;
  width: 540px;
  height: 400px;
}

.explore-measure-img {
  position: absolute;
  z-index: 1;
  width: 540px;
  height: 400px;
}

.small-thumb {
  height: 50px;
}

.bg-engage {
  background-color: #fff2d3;
}

.bg-engage-100 {
  overflow-x: auto;
}

.ant-list-split .ant-list-item {
  border-bottom: none;
}

.invisibility {
  visibility: hidden;
}

.visibile {
  visibility: visible;
}

.success-radio {
  accent-color: #42b69f;
}

.danger-radio {
  accent-color: #db2c66;
}

.thead-dark .ant-table-thead > tr > th {
  background-color: $text-color-dark;
  color: #ffffff;
}

.thead-dark .ant-table-thead > tr > th:hover {
  background-color: $text-color-dark;
  color: #fff;
}

.thead-warning .ant-table-thead > tr > th {
  background-color: #ffede9;
  color: $text-color-dark;
}

.class-table-bg-warning {
  background-color: #ffede9;
}

.thead-warning .ant-table-tbody > tr > td {
  background-color: #ffede9;
  color: $text-color-dark;
}

// --------------------
.thead-success .ant-table-thead > tr > th {
  background-color: #e9faef;
  color: $text-color-dark;
}

.class-table-bg-success {
  background-color: #e9faef;
}

.thead-success .ant-table-tbody > tr > td {
  background-color: #e9faef;
  color: $text-color-dark;
}

.Layout_content__3nGGC {
  padding-left: 0%;
  padding-right: 0%;
}

.hr-dark {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid #000 !important;
}

.bold-para {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
}

.notes {
  background-attachment: local;
  background-image: linear-gradient(to right, white 10px, transparent 10px),
    linear-gradient(to left, white 10px, transparent 10px),
    repeating-linear-gradient(
      white,
      white 30px,
      white 30px,
      #aeaeae33 31px,
      white 31px
    );
  line-height: 31px;
  padding: 2px 10px;
}

.wrong-answer {
  color: #842029;
  background-color: #f8d7da;
  border-color: #f5c2c7;
  border-radius: 2px;
  display: block;
  flex-wrap: nowrap;
}

.success-answer {
  color: #0f5132;
  background-color: #d1e7dd;
  border-color: #badbcc;
  border-radius: 2px;
  display: inherit;
  flex-wrap: nowrap;
}

.answer-warning {
  background-color: #fa5b38;
  color: #fff;
  border-radius: 2px;
  display: inherit;
  flex-wrap: nowrap;
}

.answer-info {
  color: #084298;
  background-color: #cfe2ff;
  border-color: #b6d4fe;
  border-radius: 2px;
  display: inline-block;
  width: 100%;
  img {
    margin-right: 10px;
  }
}

.unmarked-answer {
  color: $text-color-dark;
  border-radius: 2px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  .p-proprty {
    margin-top: -5px;
  }
}

.graph-answer-radio {
  padding-top: calc(25%);
}

.result-radio-btn {
  width: 24px;
  height: 24px;
  border: 2px solid rgb(0, 0, 0);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.result-radio-btn .checkmark {
  border-radius: 50%;
  background-color: transparent;
  color: rgb(0, 0, 0);
  display: none;
}

.result-radio-btn input {
  display: none;
}

.result-radio-btn input:checked + .checkmark {
  display: inline-block;
}

.result-radio-btn .checkmark {
  border-radius: 50%;
  background-color: transparent;
  color: rgb(0, 0, 0);
  display: none;
}

.custom-radio-btn {
  width: 24px;
  height: 24px;
  border: 2px solid $text-color-dark;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-radio-btn .checkmark {
  border-radius: 50%;
  border: 8px solid $text-color-dark;
  color: $text-color-dark;
  display: none;
}

.custom-radio-btn input-warning {
  display: none;
}

.custom-radio-btn input:checked + .checkmark {
  display: inline-block;
}

.extra-resource-table-light {
  .ant-table-thead {
    background-color: #fff;
  }
}

.math-help {
  .ant-list-item {
    padding: 0%;
  }
}

.table-heading-none {
  .ant-table-thead {
    display: none;
  }
}

// --------------------
.thead-success .ant-table-thead > tr > th {
  background-color: #e9faef;
  color: $text-color-dark;
}

.class-table-bg-success {
  background-color: #e9faef;
}

.thead-success .ant-table-tbody > tr > td {
  background-color: #e9faef;
  color: $text-color-dark;
}

.Layout_content__3nGGC {
  padding-left: 0%;
  padding-right: 0%;
}

.bold-para {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
}

.notes {
  background-attachment: local;
  background-image: linear-gradient(to right, white 10px, transparent 10px),
    linear-gradient(to left, white 10px, transparent 10px),
    repeating-linear-gradient(
      white,
      white 30px,
      white 30px,
      #aeaeae33 31px,
      white 31px
    );
  line-height: 31px;
  padding: 2px 10px;
}

.ant-list-item {
  padding: 0;
}

.graph-answer-radio {
  margin-top: 0px;
}

.answer-border {
  border: 3px solid #09bc98;
}

.bg-explore {
  background-color: #e9faef;
}

.bg-evaluate {
  background-color: #ffede9;
}

.bg-explain {
  background-color: #e1f1ff;
}

.bg-extend {
  background-color: #bababa;
}

.lab-description {
  .ant-table-thead > tr > th {
    background-color: $text-color-dark;
    color: white;
  }

  .ant-table-tbody > tr > td,
  .ant-table-thead > tr > th {
    border-bottom: 1px solid $text-color-dark;
    border-right: 1px solid $text-color-dark;
    text-align: center !important;
    padding: 10px 10px !important;
  }

  .ant-table-container table > thead > tr:first-child th:last-child {
    border-right: none;
  }

  .ant-table-container table > tbody > tr td:last-child {
    border-right: 1px solid $text-color-dark;
  }

  .ant-table-container table > tbody > tr td:first-child {
    border-left: 1px solid $text-color-dark;
  }
}

.img-sm {
  width: 170px;
}

.steps-content {
  background-color: #fafafa;
  border: 1px solid #188efb;
  border-radius: 2px;
}

.steps-action {
  margin-top: 24px;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background: #000000;
  border-color: #000000;
}

.ant-steps-item-finish .ant-steps-item-icon {
  border-color: #000000;
  color: #000000;
}

.ant-steps-item-title::after {
  border-color: #000000;
  color: #000000;
}

.demo-editor {
  background-color: white;
  border: 1px solid #f1f1f1;
}

.ant-drawer-content-wrapper {
  width: 60vw !important;

  label {
    font-weight: bold;
  }
}

.edit-lesson .ant-drawer-content-wrapper {
  width: 80vw !important;

  label {
    font-weight: bold;
  }
}

.rwt__tablist[aria-orientation="vertical"]
  .rwt__tab[aria-selected="true"]:after {
  border-right: 5px solid $text-color-dark !important;
}

.breadcrumb-h3 {
  font-size: 32px;
}

.upload-btn {
  color: $text-color-dark !important;
}

.question-headder-style {
  background-color: $text-color-dark;
  color: #e1f0ff;
  padding-top: 10px;
  padding-bottom: 9px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
}

.overflow-hidden-x {
  overflow-x: hidden;
}

.percent-50-drawer .ant-drawer-content-wrapper {
  width: 50vw !important;
}

.home-icon {
  position: fixed;
  right: 125px;
  z-index: 999;
  top: 35px;
  cursor: pointer;
  color: #495057;
}

.notification-bubble {
  position: fixed;
  right: 25px;
  z-index: 999;
  top: 15px;
  cursor: pointer;
  background-color: #262626;
  color: #fff;
  padding: 5px;
  border-radius: 3px;
  font-size: 12px;
}

.back-icon {
  position: fixed;
  right: 200px;
  // z-index: 999999;
  z-index: 999;
  top: 35px;
  cursor: pointer;
  color: #495057;
}

.description-images-60 {
  img {
    width: 60px;
    height: 60px;
  }
}

.display-flex {
  display: flex;
}

.fixed-tab {
  height: 100vh;
  overflow: hidden;
}

.scroll-tab {
  height: 85vh;
  overflow: hidden;
  overflow-y: scroll;
}

// .floating-button-center {
//   position: fixed;
//   bottom: calc(6vh);
//   left: calc(50vh-200px);
// }

// .floating-button-left {
//   position: fixed;
//   bottom: calc(6vh);
//   left: 10vw;
// }
// .floating-button-right {
//   position: fixed;
//   bottom: calc(6vh);
//   right: 10vw;
// }
.bold {
  font-weight: bold !important;
}

.pt-6 {
  position: relative;
  top: 6px;
}

.dlabel {
  font-size: 12px !important;
  word-break: break-word;
}

// table column width start
.fixed-size {
  width: 30%;
}

// table column width end

// change default password modal
.change-pw-button {
  .ant-btn-primary {
    border-color: $text-color-dark !important;
    background-color: $text-color-dark !important;
  }
}

.required-field {
  color: red;
  // font-size: large;
  font-weight: bold;
  padding-right: 5px;
}

.drawer-ant-divider-horizontal {
  margin: 10px 0 20px 0 !important;
}

.btn-engage {
  background-color: $engage-color;
  padding: 12px 20px;
  color: $text-color-light;
}
.btn-engage:hover {
  border: 1px solid $engage-color;
  background-color: white;
  padding: 12px 20px;
  color: $engage-color;
}

.btn-explore {
  background-color: $explore-color;
  padding: 12px 20px;
  color: $text-color-light;
}

.btn-explore:hover {
  border: 1px solid $explore-color;
  background-color: white;
  padding: 12px 20px;
  color: $explore-color;
}

.btn-evaluate {
  background-color: $evaluate-color;
  padding: 12px 20px;
  color: $text-color-light;
}
.btn-evaluate:hover {
  border: 1px solid $evaluate-color;
  background-color: white;
  padding: 12px 20px;
  color: $evaluate-color;
}

.btn-explain {
  background-color: $explain-color;
  padding: 12px 20px;
  color: $text-color-light;
}
.btn-explain:hover {
  border: 1px solid $explain-color;
  background-color: white;
  padding: 12px 20px;
  color: $explain-color;
}

.btn-extend {
  background-color: $extend-color;
  padding: 12px 20px;
  color: $text-color-light;
}
.btn-extend:hover {
  border: 1px solid $extend-color;
  background-color: white;
  padding: 12px 20px;
  color: $extend-color;
}

.btn-fixed-1 {
  width: 100px;
}

// Scrollbar

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

// input type number scroll hide

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.px--15px {
  margin: 0 -15px;
}

// Sun editor changes
.se-notice span {
  position: absolute;
  left: -150px;
}

.se-notice span:before {
  content: "[SUNEDITOR.imageUpload.fail] ";
  position: absolute;

  background-color: #f2dede;
  color: #f2dede;
}
.sun-editor .se-notice button {
  color: #913838 !important;
}
// custom-image-uploader
.ant-upload.ant-upload-drag .ant-upload {
  padding: 0 !important;
  margin: 16px 0;
}

.upload{
  .ant-upload.ant-upload-drag{
    border: none;
  }
  margin-top: -16px;
  .ant-upload.ant-upload-drag .ant-upload{
    margin: 16px 0 0 0;
  }
}

.DragAndDropUploaderLabel {
  background-color: #000;
  font-size: medium;
  border-radius: 5px;
  padding: 15px 5px;
  margin: 15px 10px;
  font-weight: 800;
  color: #fff;
  .download {
    background-color: #fff;
    color: #0f763f;
  }
  .download:hover {
    color: #fff;
    background-color: #0f763f;
    border-color: #000;
  }
}

.import-drawer {
  .ant-upload.ant-upload-drag {
    height: 400px;
  }
  .ant-drawer-content-wrapper {
    width: 500px !important;
  }
}

.error-bg {
  background: rgb(69, 87, 162);
  background: linear-gradient(
    90deg,
    rgba(69, 87, 162, 1) 0%,
    rgba(0, 159, 227, 1) 53%,
    rgba(15, 112, 183, 1) 80%,
    rgba(69, 87, 162, 1) 100%
  );
}

.error-code {
  font-size: 120px;
  font-weight: 900;
  color: $text-color-light;
}

.error-message {
  font-size: 18px;
  font-weight: 400;
  color: $text-color-light;
}

.btn-back {
  background-color: $text-color-light;
  color: $extend-color;
  width: 200px;
  border: 1px solid $explain-color;
}

.btn-contact {
  background-color: $extend-color;
  color: $text-color-light;
  width: 200px;
  border: 1px solid $extend-color;
}

.btn-back:hover {
  background-color: $text-color-light;
  color: $extend-color;
  width: 200px;
  border: 1px solid $explain-color;
}

.btn-contact:hover {
  background-color: $extend-color;
  color: $text-color-light;
  width: 200px;
  border: 1px solid $extend-color;
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  border-color: none !important;
  box-shadow: none !important;
}

.ant-select-focused {
  border-color: none !important;
  box-shadow: none !important;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: none !important;
  box-shadow: none !important;
}

.ant-select-focused:not(.ant-select-disabled)
  .ant-select:not(ant-select-customize-input)
  .ant-select-selector {
  border-color: none !important;
  box-shadow: none !important;
}

.bubble-chart {
  width: 420px !important;
  height: 420px !important;
  .time {
    margin-top: 90px !important;
  }
}

@media (max-width: 540px) {
  .bubble-chart {
    flex-basis: 100%;
  }
}

.time-bublegraph {
  margin-top: 100px !important;
  width: 420px !important;
}

.width-star-path{
  width: 420px !important;
}

@media (max-width: 768px) {
  .p25r {
    padding-left: 0;
    padding-right: 0;
  }
}
.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination-disabled:hover .ant-pagination-item-link {
  .anticon {
    position: relative;
    top: -1px;
  }
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  .anticon {
    position: relative;
    top: -1px;
  }
}

.sso-logo-btn {
  display: flex;
}

.sso-logo-btn .sso-logo-img {
  width: 26px;
  height: 26px;
  margin-right: 10px;
}

.sso-logo-btn .text {
  flex-grow: 1;
}

.btn-clever {
  background-color: #3158d7 !important;
  border-color: #3158d7 !important;
}
.btn-clever:hover {
  background: linear-gradient(
    to right,
    #3158d7 88px,
    transparent 88px
  ) !important;
  border-color: #3158d7 !important;
  color: #3158d7 !important;
}

.btn-otave {
  background-color: #004d40 !important;
  border-color: #004d40 !important;
}
.btn-otave:hover {
  background: linear-gradient(
    to right,
    #004d40 88px,
    transparent 88px
  ) !important;
  border-color: #004d40 !important;
  color: #004d40 !important;
}

.btn-classlink {
  background-color: #12AACD !important;
  border-color: #12AACD !important;
}
.btn-classlink:hover {
  background: linear-gradient(
    to right,
    #12AACD 88px,
    transparent 88px
  ) !important;
  border-color: #12AACD !important;
  color: #12AACD !important;
}

.mt-10 {
  margin-top: 10px;
}

.text-or {
  color: #aeaeae;
  font-size: 12px;
}

.ruler-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2rem 0;
}

.ruler {
  width: 100%;
  height: 1px;
  background-color: #aeaeae68;
  position: relative;
}

.ruler-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 0 10px;
  color: #aeaeae;
  font-size: 14px;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  .ant-collapse-expand-icon {
    position: relative;
    top: -4px;
  }
}

.ant-pagination-jump-prev:hover,
.ant-pagination-jump-next:hover {
  position: relative;
  top: -4px;
}

// .anticon .anticon-check
.ant-steps-finish-icon {
  position: relative;
  top: -4px;
}

.ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-icon
  .ant-steps-icon {
  position: relative;
  top: -2px;
}

.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  position: relative;
  top: -2px;
}

.ant-steps-dot .ant-steps-item-process .ant-steps-icon:first-child .ant-steps-icon-dot, .ant-steps-dot.ant-steps-small .ant-steps-item-process .ant-steps-icon:first-child .ant-steps-icon-dot{
  position: relative;
  top: 2px;
}

.ant-modal-body{
  width: 100% !important;
  p{
    width: 90% !important;
  }
}




// Regression change
.ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector{
  border-color: #e6e5e5 !important;
}

.stick-top{
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 6;
}

.ant-drawer-body{
  padding: 0 24px 24px 24px !important;
}

//Spinner

.ant-spin-nested-loading > div > .ant-spin{
  // width: 100%;
  height: 100vh;
  position: fixed !important;
  max-height: fit-content;
  z-index: 999999999999999999999999 !important;
  background: linear-gradient(to bottom, #ffffff00 100px, #0f8afe20);
  /* For modern browsers */
  background: -webkit-linear-gradient(top, #ffffff00 100px, #0f8afe20);
  /* Fallback for old browsers */
  background: -moz-linear-gradient(top, #ffffff00 100px, #0f8afe20);
  /* Fallback for Internet Explorer 10 and below */
  background: -ms-linear-gradient(top, #ffffff00 100px, #0f8afe20);
}

.ant-spin-nested-loading > div > .ant-spin .ant-spin-text{
  position: absolute;
  // top: 22%;
  // width: 50%;
  // padding-top: 5px;
  text-shadow: none !important;
  text-align: center;
}

.ant-spin-nested-loading > div > .ant-spin .ant-spin-dot{
  position: fixed;
}

.spinner-new{
  .ant-spin-text{
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 14px;
    // width: 100vw;
    margin-top: 35px;
    width: unset !important;
  }
}

.ant-steps-finish-icon {
  top: 0 !important;
}