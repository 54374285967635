@import "_labVariables.scss";

///////// Navbar //////////
.navbar {
  padding: $navbar-padding;
  border: none;
  background: $body-bg;

  .nav-link {
    padding: 0;
  }

  .input-group {
    width: 245px;
    height: 45px;

    .form-control {
      font-size: $font-size-sm;
      font-weight: $font-weight-normal;

      &::placeholder {
        color: $base-middle-gray;
      }
    }
  }
}

///////// Widget //////////
.widget-p-none {
  padding: 0;
}

.widget-p-sm {
  padding: 16px;
}

.widget-p-md {
  padding: 24px 24px;
}

.widget-p-lg {
  padding: 24px 32px;
}

///////// Breadcrumbs //////////
.breadcrumb {
  margin: 8px 0 0;
  padding: 0;
  color: $text-color;
  background-color: transparent;
  font-size: $font-size-sm;
  font-weight: $font-weight-normal;

  a {
    color: $base-dark-gray;
  }
}

///////// Grid //////////
.pl-grid-col {
  padding-left: 12px;
}

.pr-grid-col {
  padding-right: 12px;
}

.gutter > [class*="col-"] {
  padding-right: 12px;
  padding-left: 12px;
}

.pr-grid-row {
  padding-right: 3px;
}

.pl-grid-row {
  padding-left: 3px;
}

///////// Buttons //////////
.btn {
  padding: $button-padding;
  font-size: $font-size-sm;
  font-weight: $font-weight-semi-bold;

  .btn-success,
  .btn-info,
  .btn-warning,
  .btn-danger {
    color: $white;
  }
}

///////// Progress Bar //////////
.progress-xs {
  height: 6px;
}

///////// Dropdowns //////////
.dropdown-toggle {
  padding: 6px 12px 6px 6px;
  border: 1px solid $base-middle-gray;
  border-radius: 4px;
  font-size: $font-size-sm;
  font-weight: $font-weight-normal;
  color: $base-dark-gray;
  background-color: $white;

  &:hover {
    background-color: $base-light-gray;
  }

  &:focus {
    background-color: $white;
  }
}

.show > .btn-secondary.dropdown-toggle {
  color: $default;
  background-color: $base-light-gray;

  &:focus {
    background-color: $base-light-gray;
  }

  &:active {
    background-color: $base-light-gray;
  }
}

.dropdown-menu {
  font-size: $font-size-sm;
  font-weight: $font-weight-normal;
  min-width: 100%;
}

.navbar-dropdown-toggle {
  color: $text-color;
  border: none;
}

.navbar-dropdown {
  margin-top: 12px;
  padding: 24px 16px;
  box-shadow: $widget-shadow;
  border: none;

  .dropdown-item {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 13px 16px;
    border-radius: 4px;

    &:hover {
      background-color: $base-light-gray;
    }

    span {
      margin-left: 8px;
      font-size: $font-size-base;
      font-weight: $font-weight-semi-bold;
      color: $text-color;
    }

    p {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .logout-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 48px;
    padding: 8px 16px;
  }
}

.profile-dropdown {
  @media (max-width: breakpoint-max(sm)) {
    left: -160px;
  }
}

///////// Headings //////////
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: $font-weight-semi-bold;
}

///////// Headilines //////////
.headline-1 {
  font-size: $headline-large;
  font-weight: $font-weight-semi-bold;
}

.headline-2 {
  font-size: $headline-medium;
  font-weight: $font-weight-semi-bold;
}

.headline-3 {
  font-size: $headline-small;
  font-weight: $font-weight-semi-bold;
}

///////// Body fonts //////////
.body-1 {
  font-size: $font-size-base;
  font-weight: $font-weight-normal;
}

.body-2 {
  font-size: $font-size-sm;
  font-weight: $font-weight-semi-bold;
}

.body-3 {
  font-size: $font-size-sm;
  font-weight: $font-weight-normal;
}

.label {
  font-size: $font-size-mini;
  font-weight: $font-weight-normal;
}

.muted {
  color: $base-dark-gray;
}

///////// Icons //////////

.eva {
  font-size: 24px;
}

.fa {
  font-size: 24px;
}

///////// Tables //////////
.react-bootstrap-table {
  table {
    overflow-x: auto;
    width: 100%;
  }
}

.widget-table-overflow {
  width: 100%;
  margin: 0 0 24px;

  th:first-child,
  td:first-child {
    padding-left: 8px;
  }

  th:last-child,
  td:last-child {
    padding-right: 24px;
  }

  .form-check-input {
    position: relative;
  }
}

.table-bordered th {
  background-color: $gray-300;
}

table > thead > tr > th {
  border-top: none !important;
  border-bottom-width: 1px !important;
  text-transform: none;
}

.table-striped {
  thead {
    th {
      vertical-align: middle;
      border: none;
    }
  }

  tbody {
    tr:nth-of-type(odd) {
      background-color: $base-light-gray;
    }

    td {
      border: none;
    }
  }
}

.table-hover {
  tbody tr {
    @include hover() {
      background-color: $violet;

      td {
        color: white;
      }
    }
  }
}

.pagination {
  justify-content: center;
  align-items: center;
}

.page-link {
  padding: 4px 12px;
  border: $pagination-border-width solid $pagination-border-color;

  &:hover {
    background-color: transparent;
    border-color: $pagination-hover-border-color;
  }

  &:focus {
    z-index: 3;
    outline: none;
    box-shadow: none;
  }
}

.page-item {
  margin-left: 16px;
  margin-right: 16px;

  &:first-child {
    .page-link {
      border: none;
      margin-right: 80px;
      font-size: 36px;
      line-height: 1;

      @media (max-width: breakpoint-max(md)) {
        margin-right: 8px;
      }
    }
  }

  &:last-child {
    .page-link {
      margin-left: 80px;
      border: none;
      font-size: 36px;
      line-height: 1;

      @media (max-width: breakpoint-max(md)) {
        margin-left: 8px;
      }
    }
  }

  @media (max-width: breakpoint-max(md)) {
    margin-left: 4px;
    margin-right: 4px;
  }
}

.pagination-borderless {
  .page-item {
    .page-link {
      border: none;
      color: $pagination-disabled-color;
    }

    &.active .page-link {
      z-index: 3;
      color: $violet;
      background-color: transparent;
      border: none;
    }

    &:first-child {
      .page-link {
        color: $violet;
      }
    }

    &:last-child {
      .page-link {
        color: $violet;
      }
    }

    &.disabled .page-link {
      color: $pagination-disabled-color;
      pointer-events: none;
      cursor: auto;
      background-color: $pagination-disabled-bg;
      border-color: $pagination-disabled-border-color;
    }
  }
}

///////// Badges //////////
.badge {
  padding: 4px 8px;
  font-size: $font-size-sm;
  font-weight: $font-weight-semi-bold;
  color: $white;
  border-radius: 4px;
}

///////// Checkboxes //////////
.checkbox {
  padding-left: 20px;
  display: flex;
  margin-bottom: 4px;
}

.checkbox label {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  padding-left: 5px;
}

.checkbox label::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 18px;
  height: 18px;
  left: 0;
  margin-left: -20px;
  border: 1px solid $base-middle-gray;
  border-radius: 3px;
  background-color: #fff;
  -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
}

.checkbox label::after {
  display: inline-block;
  position: absolute;
  width: 17px;
  height: 17px;
  left: 0;
  top: 0;
  margin-left: -20px;
  padding-left: 3px;
  padding-top: 1px;
  font-size: 11px;
  color: #555555;
}

.checkbox input[type="checkbox"] {
  opacity: 0;
  z-index: 1;
}

.checkbox input[type="checkbox"]:disabled + label::before {
  background-color: #eeeeee;
  cursor: not-allowed;
}

.checkbox-primary input[type="checkbox"]:checked + label::before {
  background-color: $violet;
  border-color: $violet;
}

.checkbox-primary input[type="checkbox"]:checked + label::after {
  color: #fff;
}

input[type="checkbox"].styled:checked + label:after {
  top: 2px;
  content: url("../components/Icons/checkmark.svg");
}

input[type="checkbox"] .styled:checked + label::before {
  color: #fff;
}

input[type="checkbox"] .styled:checked + label::after {
  color: #fff;
}

///////// Tabs //////////

.nav-tabs {
  flex-wrap: nowrap;
  overflow-x: auto;

  .nav-link {
    margin-bottom: -$nav-tabs-border-width;
    border: $nav-tabs-border-width solid transparent;
    color: $base-dark-gray;
    @include border-top-radius($nav-tabs-border-radius);

    @include hover-focus() {
      border-color: $nav-tabs-link-hover-border-color;
    }

    &.disabled {
      color: $base-dark-gray;
      background-color: transparent;
      border-color: transparent;
    }
  }

  .nav-link.active,
  .nav-item.show .nav-link {
    color: $violet;
    background-color: $white;
    border-color: transparent;
    border-bottom: 2px solid $violet;
  }

  .nav-link:hover {
    color: $violet;
    border-color: transparent;
    border-bottom: 2px solid $violet;
  }
}

////////// Alerts /////////
.alert {
  border: none;
  padding: 12px 36px 12px 16px;
  color: $white;

  .btn {
    border: none;
  }

  button.close {
    font-weight: $font-weight-thin;
    font-size: 20px;
    text-shadow: none;
    opacity: 1;
    color: #fff;
    outline: none;
    padding: 0;
    position: absolute;
    top: auto;
    right: 16px;
    margin-left: 8px;

    &.alert-transparent {
      color: inherit;
      opacity: 1;
    }
  }
}

///////// Notification button //////////
.notification-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  width: 100%;
  border: none;
  border-radius: 8px;
  color: $white;
}

///////// Toasts //////////

.Toastify__toast-container {
  padding: 0;
  background-color: transparent;
  box-shadow: none;
  width: auto;
}

.Toastify__toast {
  background-color: transparent;
  box-shadow: none;
  margin-bottom: 0;
  padding: 0;
}

.Toastify__toast-body {
  padding: 0;
  width: 460px;
}

///////// React-Bootstrap-Table //////////

///////// Other //////////

.nav {
  .dropdown-toggle {
    background-color: transparent;
  }
}

.dropdown-toggle::after {
  font-family: Line Awesome Free, sans-serif;
  content: "\F107";
  border: none;
  width: auto;
  height: auto;
  vertical-align: baseline;
  font-size: 85%;
  font-weight: 600;

  @media (max-width: breakpoint-max(xs)) {
    display: none;
  }
}

/*Right Bar */
/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 25px;
  right: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #231f20;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #363636;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #3a3a3a;
  // padding: 2.5em 1.5em 0;
  padding-top: 2.5em;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #3a3a3a;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: transparent !important;
}

.navbar {
  padding: 24px 24px 8px;
  border: none;
  background: #fff;
  position: fixed;
  z-index: 99;
  width: 100%;
}

/*
.bm-overlay {
  display: none;
}
*/

.logo {
  width: 230px;
}

.bm-cross {
  width: 3px;
  height: 28px !important;
  background: #ffffff;
}

.menu-title {
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: #fff;
  margin-bottom: 10px;
}

.lesson-image {
  position: relative;
  width: 100%;
  border-radius: 4px;
  transition: top 200ms ease 0s;
  /* only transition top property */
  top: 0;
  cursor: pointer;
  margin-bottom: 15px;
}

.lesson-image:hover {
  top: -8px;
}

.image {
  display: block;
  width: 100%;
  height: auto;
}

.imageLessonStyle {
  display: block;
  width: 100%;
  height: 250px;
}

.overlay {
  position: absolute;
  bottom: 0;
  background: rgba(0, 0, 0, 0.85);
  /* Black see-through */
  color: #f1f1f1;
  width: 100%;
  transition: 0.5s ease;
  opacity: 1;
  color: white;
  font-size: 20px;
  padding: 20px;
  text-align: center;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  min-height: 120px;
}

.g-card-top {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #aeaeae;
  display: flex;
  align-items: center;
}

.g-card-title {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 33px;
  text-align: left;
  color: #ffffff;
}

.progress {
  display: flex;
  height: 0.5rem;
  overflow: hidden;
  line-height: 0;
  /* font-size: 0.75rem; */
  background-color: transparent;
  border: 0.5px solid #fff !important;
  border-radius: 12px;
  margin-top: 15px;
  width: 90%;
}

.bg-success {
  background-color: #42b69f !important;
}

.flex {
  display: flex;
}

.value {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  padding-left: 10px;
  padding-top: 5px;
  color: #ffffff;
}

.g-card-top-right {
  font-style: normal;
  font-weight: 700;
  font-size: 8px;
  line-height: 7px;
  /* identical to box height, or 89% */

  letter-spacing: 0.1em;

  position: absolute;
  right: 30px;

  color: #42b69f;
}

.g-card-title-right {
  position: absolute;
  right: 20px;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;

  /* SS Success */

  color: #42b69f;
}

.done {
  border: 6px solid #42b69f;

  .overlay {
    border-radius: 0;
  }
}

.check {
  width: 61px;
  height: 54px;
  background-color: #42b69f;
  background-image: url("../assets/images/check.svg");
  background-repeat: no-repeat, repeat;
  background-repeat: no-repeat, repeat;
  position: absolute;
  right: -1px;
  top: -1px;
  background-position: 50%;
}

video {
  width: 100%;
  max-height: 100%;
}

.modal-content {
  background: $extend-color;
  border-radius: 4px;
  color: #fff;
}

.modal-header {
  border-bottom: none;
}

.modal-footer {
  border-top: none;
}

.close {
  float: right;
  font-size: 2rem;
  font-weight: 300;
  line-height: 1;
  color: #fff !important;
  text-shadow: none;
  opacity: 1 !important;
}

.btn-success {
  background: #42b69f;
  border-color: #42b69f;
  align-items: center;
  padding: 14px 31px;
  border-radius: 1px;
}

.btn-success:hover {
  background: #287767;
  border-color: #287767;
}

.btn-warning {
  background: #db2c66;
  border-color: #db2c66;
  align-items: center;
  padding: 14px 31px;
  border-radius: 1px;
  color: #fff;
}

.btn-warning:hover {
  background: #a31e4a;
  border-color: #a31e4a;
}

.btn-primary {
  background: $extend-color;
  border-color: $extend-color !important;
  align-items: center;
  padding: 14px 31px;
  border-radius: 1px;
  color: #fff;
}

.btn-primary:hover {
  background: #fff;
  border-color: $extend-color;
  color: $extend-color;
}

.popup-text {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: #fff;
  text-align: center;
  margin-top: 1.5rem;
}

.center {
  justify-content: center;
  padding-bottom: 50px;
}

.atab {
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
    width: 100%;
  }

  .ant-tabs-tab {
    width: 50%;
  }
}

.mr-15 {
  margin-right: 15px;
}

.leftbar {
  width: 200px;
  height: 60vh;
  position: absolute;
  padding-left: 3rem;
  padding-top: 1rem;
  margin-top: 8rem;
  border-right: 1px solid #000;
  background: #fff;
  z-index: 99;
}

.leftbar li {
  padding-top: 15px;
}

.bm-menu {
  padding-left: 0;
}

.rmenu {
  padding-top: 10px;
}

.menu-item {
  color: #fff;
  font-size: 14px;
}

.lesson-tab {
  /* margin-top: -125px; */
  position: relative;
  z-index: 9;
}

.ant-tabs-nav {
  background: #fff;
  position: fixed;
}

.bootbox {
  z-index: 99999999;
}

.fade {
  transition: opacity 0.15s linear;
  z-index: 99999999999 !important;
}

.modal-backdrop.show {
  display: none;
}

.content {
  position: relative;
}

.ltab {
  .ant-tabs-content-holder {
    margin-top: 95px;
  }

  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
    width: 100%;
  }

  .ant-tabs-tab {
    width: auto;
  }

  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
    position: relative;
    display: block;
    transition: transform 0.3s;
    align-items: center;
    text-align: center;
  }

  .ant-tabs-tab {
    padding: 25px 25px 50px 20px;
  }

  .ant-tabs-top > .ant-tabs-nav,
  .ant-tabs-bottom > .ant-tabs-nav,
  .ant-tabs-top > div > .ant-tabs-nav,
  .ant-tabs-bottom > div > .ant-tabs-nav {
    margin: 0;
  }
}

.fixed-ltab {
  .ant-tabs > .ant-tabs-nav,
  .ant-tabs > div > .ant-tabs-nav {
    position: fixed;
  }

  .ant-tabs-content-holder {
    margin-top: 95px;
  }
}

.inner-tab {
  .ant-tabs > .ant-tabs-nav,
  .ltab .ant-tabs > div > .ant-tabs-nav {
    position: relative;
    z-index: 9;
  }

  .ant-tabs-content-holder {
    margin-top: 0;
  }

  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
    width: 100%;
  }

  .ant-tabs-tab {
    width: auto;
  }

  .ant-tabs-tab-btn {
    font-weight: 600;
  }

  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
    position: relative;
    display: block;
    transition: transform 0.3s;
    align-items: left !important;
    text-align: left !important;
    background: transparent !important;
  }

  .ant-tabs-tab {
    padding: 5px 15px !important;
    color: $explore-color !important;
    border: 1px solid $explore-color !important;
    background: #fff !important;
    border-radius: 9.88337px 9.88337px 0px 0px !important;
    border-bottom: none;
  }

  .ant-tabs-top > .ant-tabs-nav,
  .ant-tabs-bottom > .ant-tabs-nav,
  .ant-tabs-top > div > .ant-tabs-nav,
  .ant-tabs-bottom > div > .ant-tabs-nav {
    margin: 0 !important;
  }

  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0 0 0 10px !important;
  }

  .ant-tabs-tab-active {
    background: $explore-color !important;
    border-radius: 9.88337px 9.88337px 0px 0px !important;
    color: #fff !important;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #fff !important;
  }
}

.engage {
  .active {
    background: $engage-color;
  }
}

.engage-tab {
  background: $engage-color !important;

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #fff !important;
  }

  .ant-tabs-tab-btn {
    color: #fff !important;
  }
}

.explore-tab {
  background: $explore-color !important;

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #fff !important;
  }

  .ant-tabs-tab-btn {
    color: #fff !important;
  }
}

.evaluate-tab {
  background: $evaluate-color !important;

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #fff !important;
  }

  .ant-tabs-tab-btn {
    color: #fff !important;
  }
}

.explain-tab {
  background: $explain-color !important;

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #fff !important;
  }

  .ant-tabs-tab-btn {
    color: #fff !important;
  }
}

.expand-tab {
  background: $extend-color !important;

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #fff !important;
  }

  .ant-tabs-tab-btn {
    color: #fff !important;
  }
}

.p25r {
  padding-left: 5rem;
  padding-right: 5rem;
}

.explore-page {
  border: 20px solid $explore-color;
  border-radius: 4px;

  .card-chart {
    background: #fbfbfb !important;
    border-radius: 0;
  }

  img,
  .ant-image,
  .ant-tabs {
    max-width: 100%;
  }
}

.explore-measure-img {
  width: 100%;
}

.fix-width-image {
  width: 540px !important;
}

.engage-page {
  border: 20px solid $engage-color;
  border-radius: 4px;

  .card-chart {
    background: #fbfbfb !important;
    border-radius: 0;
  }
  img,
  .ant-image,
  .ant-tabs {
    max-width: 100%;
  }
}

.tab-style {
  .p-proprty,
  p {
    font-size: 18px;
    line-height: 24px;
  }

  h3 {
    font-size: 30px;
    font-weight: 700;
    line-height: 30px;
    font-style: normal;
  }

  h5 {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    font-style: normal;
  }

  .graph-description {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .used-hypo {
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
  }

  .body-bold {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
  }
}

.engage-tab {
  background: $engage-color;
}

.evaluate-page {
  border: 20px solid $evaluate-color;
  border-radius: 4px;

  .card-chart {
    border-radius: 0;
  }

  .card {
    border: none;
  }
}

.explain-page {
  border: 20px solid $explain-color;

  border-radius: 4px;

  .card-chart {
    border-radius: 0;
  }

  .card {
    background: #fbfbfb !important;
    border: none;
  }

  h4 span {
    z-index: 99999;
    padding: 0 20px;
    margin-top: 10px;
    position: relative;
    top: 10px;
    background: #ddecfa;
  }
}

.extend-page {
  border: 20px solid $extend-color;

  border-radius: 4px;

  .card-chart {
    border-radius: 0;
  }

  .card {
    background: #fbfbfb !important;
    border: none;
  }

  h4 span {
    z-index: 99999;
    padding: 0 20px;
    margin-top: 10px;
    position: relative;
    top: 10px;
    background: #acacac;
  }
}

.p2 {
  padding: 1.5rem;
}

.ant-tabs-ink-bar {
  height: 0px;
  background: transparent !important;
}

.lab-logo {
  width: 200px;
}

.lab-hypothesis {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  background: #fff;
  padding: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  width: fit-content;
  /* grey/dark */

  color: #545454;
}

.lab-hypothesis-active {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  background: $explore-color;
  padding: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  width: fit-content;
  /* grey/dark */

  color: #fff;
}

.experiment-dropdown {
  margin-bottom: 10;
  max-width: 540px;
  // float: right;
  // position: relative;
  // top: 30px;
  z-index: 99;

  .ant-form-item-label > label {
    font-weight: 700;
  }
}

.pt5r {
  padding-top: 5rem;
}

.pt4r {
  padding-top: 4rem;
}

.xy-table {
  background: #ffffff;
  border: 1px solid #a2a2a2;
  border-radius: 4px;
  width: 100%;
  margin-left: 0;
  padding: 0.2rem;
  // width: 90%;
  max-height: 540px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 10px;
  margin-top: 1.8rem;
  max-width: 300px;

  .table th,
  .table td {
    border: none;
    text-align: center;
  }

  th {
    font-weight: 700;
    font-size: 12px;
    line-height: 19px;
    color: #545454;
  }

  td {
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    color: #545454;
  }

  .table th,
  .table td {
    padding: 0.2rem;
  }
}

.filters {
  background: #ffffff;
  border: 1px solid #a2a2a2;
  border-radius: 4px;
  width: 100%;
  padding: 1.5rem;
  width: 200px;
  overflow-x: auto;

  .chk-div {
    margin-top: 10px;
    display: flex;

    .bold {
      .ant-checkbox + span {
        font-weight: 700;
        position: relative;
        top: -2px;
      }
    }
  }

  // .ant-checkbox-wrapper {
  //   width: 50px;
  // }

  .Walk-color {
    width: fit-content;
    padding: 3px 6px;
    border-radius: 5px;
    background: #9cbdef;
    position: relative;
    top: 0px;
    left: -5px;
  }

  .Jog-color {
    width: fit-content;
    padding: 3px 6px;
    border-radius: 5px;
    background: #bb81cc;
    position: relative;
    top: 0px;
    left: -5px;
  }

  .Run-color {
    width: fit-content;
    padding: 3px 6px;
    border-radius: 5px;
    background: #86b389;
    position: relative;
    top: 0px;
    left: -5px;
  }

  .Skate-color {
    width: fit-content;
    padding: 3px 6px;
    border-radius: 5px;
    background: #ffb6a6;
    position: relative;
    top: 0px;
    left: -5px;
  }

  .Bike-color {
    width: fit-content;
    padding: 3px 6px;
    border-radius: 5px;
    background: #b3877d;
    position: relative;
    top: 0px;
    left: -5px;
  }

  .June-color {
    width: fit-content;
    padding: 3px 6px;
    border-radius: 5px;
    background: #3e68a7;
    position: relative;
    top: 0px;
    left: -5px;
  }

  .July-color {
    width: fit-content;
    padding: 3px 6px;
    border-radius: 5px;
    background: #86b389;
    position: relative;
    top: 0px;
    left: -5px;
  }

  .September-color {
    width: fit-content;
    padding: 3px 6px;
    border-radius: 5px;
    background: #ffb6a6;
    position: relative;
    top: 0px;
    left: -5px;
  }

  .October-color {
    width: fit-content;
    padding: 3px 6px;
    border-radius: 5px;
    background: #b3877d;
    position: relative;
    top: 0px;
    left: -5px;
  }

  .November-color {
    width: fit-content;
    padding: 3px 6px;
    border-radius: 5px;
    background: #77d5d8;
    position: relative;
    top: 0px;
    left: -5px;
  }

  .December-color {
    width: fit-content;
    padding: 3px 6px;
    border-radius: 5px;
    background: #a9b55a;
    position: relative;
    top: 0px;
    left: -5px;
  }

  .August-color {
    width: fit-content;
    padding: 3px 6px;
    border-radius: 5px;
    background: #bb81cc;
    position: relative;
    top: 0px;
    left: -5px;
  }

  .m70CCT1 {
    width: fit-content;
    padding: 3px 6px;
    border-radius: 5px;
    background: #3e68a7;
    position: relative;
    top: 0px;
    left: -5px;
  }

  .m70CCT2 {
    width: fit-content;
    padding: 3px 6px;
    border-radius: 5px;
    background: #86b389;
    position: relative;
    top: 0px;
    left: -5px;
  }

  .a60CCT2 {
    width: fit-content;
    padding: 3px 6px;
    border-radius: 5px;
    background: #ffb6a6;
    position: relative;
    top: 0px;
    left: -5px;
  }

  .a60CCT1 {
    width: fit-content;
    padding: 3px 6px;
    border-radius: 5px;
    background: #b3877d;
    position: relative;
    top: 0px;
    left: -5px;
  }

  .a20CCT2 {
    width: fit-content;
    padding: 3px 6px;
    border-radius: 5px;
    background: #a9b55a;
    position: relative;
    top: 0px;
    left: -5px;
  }

  .a20CCT1 {
    width: fit-content;
    padding: 3px 6px;
    border-radius: 5px;
    background: #bb81cc;
    position: relative;
    top: 0px;
    left: -5px;
  }

  .ant-checkbox-inner {
    width: 22px;
    height: 22px;
  }

  .ant-checkbox-inner::after {
    position: absolute;
    top: 45%;
    left: 25.5%;
    display: table;
    width: 5.71428571px;
    height: 12.14285714px;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #545454;
    border-color: #545454;
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #545454;
  }
}

.time {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  padding-bottom: 0.5rem;
  margin-top: -20px;

  /* grey/dark */

  color: #545454;
}

.b-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-top: 0rem;

  color: #545454;
}

.plus {
  width: 20px !important;
  margin-left: 20px;
}

.round {
  background: #545454;
  border-radius: 25.322px;
  margin-left: 0px;
}

.fa-arrow-right {
  font-size: 14px;
}

.mt-50 {
  margin-top: 100px;
}

.ant-tabs-nav {
  position: fixed;
  width: 100%;
  background: transparent;
  z-index: 99999999;
}

.graph {
  width: 70%;
}

.mt-30 {
  margin-top: 30px;
}

.black {
  border-top: 1px solid $extend-color;
}

.icon {
  margin-right: 5px;
}

.line {
  border-bottom: 2px solid $extend-color;
  padding-bottom: 35px;
}

.graph-question input[type="radio"],
input[type="checkbox"] {
  position: relative;
}

.ant-image-mask:hover {
  opacity: 0;
}

.ev-tab {
  border: 1px solid #545454;
  border-radius: 4px;
}

.ex-tab {
  border-radius: 4px;

  .active {
    font-weight: bold;
    background: #e1f1ff !important;
  }
}

.bg-evaluate {
  background: #ffede9;
}

.bg-evaluate-100 {
  overflow-x: auto;
}

.eve-question {
  .form-check-input {
    margin-left: 1rem;
  }

  label {
    position: relative;
    left: 10px;
    top: -2px;
  }
}

.ml-1 {
  margin-left: 1rem !important;
}

.p-25 {
  padding: 2rem;
}

.pl2 {
  padding-left: 1rem;
}

.strong {
  font-weight: 600;
}

.white-card {
  background: #fff;
  border-radius: 4px;
  padding: 2.5rem;
}

.green-border {
  border: 2px solid #42b69f;
  border-radius: 4px;
}

.tarea {
  resize: none;
  background: #ffffff;
  border: 1px solid rgba(174, 174, 174, 0.5) !important;
  border-radius: 4px !important;
  padding: 1.5rem;
}

.i-width {
  width: 90%;
}

.no-border {
  border: none !important;
}

.disc {
  list-style-type: disc;
  margin-left: 1rem;
}

.lab-nav {
  // z-index: 999999999999999999;
  z-index: 1001;
  width: 15%;
}

.ltab .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
.ltab .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
  background: #fff;
}

.bm-burger-button {
  z-index: 999 !important;
}

.bm-menu-wrap {
  z-index: 99999999999999999999 !important;
}

.chk-all {
  .all {
    position: relative;
    top: -2px;
  }
}

.engage-tab-back {
  background: #fff8e8 !important;
}

.explore-tab-back {
  background: #e9faef !important;
}

.evaluate-tab-back {
  background: #ffede9 !important;
}

.explain-tab-back {
  background: #ddecfa !important;
}

.expand-tab-back {
  background: #acacac !important;
}

.navbar {
  background: transparent !important;
}

.filters {
  input[type="radio"],
  input[type="checkbox"] {
    position: relative;
    display: none !important;
  }
}

.video-lg {
  // width: 100% !important;
  width: 540px !important;
}

.numeric-value-holder {
  display: none !important;
}

.form-check {
  input[type="radio"],
  input[type="checkbox"] {
    display: inline-block;
    margin-right: 5px;
    margin-top: 5px;
  }
}

.pt5rl {
  margin-top: 2rem;
}

.btn-lg,
.btn-group-lg > .btn,
.btn-group-lg > .btn {
  border-radius: 4px !important;
}

.btn-primary:focus,
.btn-primary.focus {
  background: #fff;
  border-color: $extend-color;
  color: $extend-color;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  background: #fff;
  border-color: $extend-color;
  color: $extend-color;
}

.modal-open div {
  z-index: 99 !important;
}

.hype-button {
  background: $extend-color;
  border-radius: 1px;
  justify-content: center;
  align-items: center;
  padding: 4px 16px;
  font-size: 10px;
  margin-left: 20px;
}

.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  border: none !important;
}

.__myAlertModal .alert-wrapper .alert-close-icon {
  font-size: 24px;
  width: 20px;
  position: absolute;
  right: 20px;
  top: 5px;
  color: #95969f;
  font-weight: 400;
  border: none;
  background: transparent;
}

.__myAlertModal .alert-wrapper {
  max-width: 500px;
  width: 100%;
  height: auto;
  margin: auto;
  padding: 2em;
  position: relative;
  border-radius: 4px;
  box-shadow: 0 3px 20px 2px #c5c5c5;
  background-color: $extend-color !important;
}

.__myAlertModal .alert-dialogue .alert-text {
  margin: 0 0 20px;
  padding: 0;
  font-family: Merriweather;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff !important;
  position: relative;
  text-align: center;

  font-family: "CarmenSans-Regular" !important;
}

.__myAlertModal .alert-dialogue {
  text-align: center;
}

.__myAlertModal .alert-status-icon {
  display: none;
}

.__myAlertModal .alert-dialogue .close-alert-btn {
  margin: 10px 0 0;
  padding: 6px;
  border: none;
  text-transform: capitalize;
  width: 140px;
  height: 42px;
  font-family: "Rubik", sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.35px;
  text-align: center;
  color: #fff;
  background: #42b69f !important;
  border-radius: 1px;
}

.chart {
  margin-top: 10px;
}

.lesson-select {
  .back-button {
    color: #545454;
    float: left;
    position: relative;
    top: -50px;
  }
}

.teacher-details {
  sub-title {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: $extend-color;
  }

  h3 {
    font-weight: 700;
    font-size: 48px;
    line-height: 56px;
    color: $extend-color;
    margin-bottom: 3rem;
  }

  .back-button {
    color: #545454;
    float: left;
    position: relative;
    top: -50px;
  }

  .arrow {
    position: relative;
    top: 3px;
  }

  .ant-tabs-content-holder {
    margin-top: 0px !important;
  }
}

.t-tabs .ant-tabs > .ant-tabs-nav,
.t-tabs .ant-tabs > div > .ant-tabs-nav {
  position: relative !important;
}

.t-tabs {
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
    width: 100%;
  }

  .no-padding {
    padding: 0 !important;
  }

  .t-tabs .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
  .t-tabs .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
    background: #fff !important;
  }

  .ant-tabs-tab {
    width: auto;
  }

  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
    position: relative;
    display: block;
    transition: transform 0.3s;
    align-items: center;
    text-align: center;
  }

  .ant-tabs-tab {
    padding: 16px 25px;
    border-radius: 4px 4px 0px 0px;
  }

  .ant-tabs-top > .ant-tabs-nav,
  .ant-tabs-bottom > .ant-tabs-nav,
  .ant-tabs-top > div > .ant-tabs-nav,
  .ant-tabs-bottom > div > .ant-tabs-nav {
    margin: 0;
  }

  .ant-tabs > .ant-tabs-nav,
  .ant-tabs > div > .ant-tabs-nav {
    position: fixed;
  }

  .engage-page {
    border: 15px solid $engage-color;
    border-radius: 0px;
    background-color: #fff2d3 !important;
  }

  .engage-color {
    background-color: #fff2d3;
  }

  .evaluate-page {
    border: 15px solid $evaluate-color;
    border-radius: 0px;
    background: #ffede9;
  }

  .explain-page {
    border: 15px solid $explain-color;
    border-radius: 0px;
    background: #ddecfa;
  }

  .extend-page {
    border: 15px solid $extend-color;
    border-radius: 0px;
    background: #acacac;
  }

  .explore-page {
    border: 15px solid $explore-color;
    border-radius: 0px;
    background: #e9faef;
  }
}

.h4-center {
  width: 50%;
  text-align: center;
  border-bottom: 1px solid $extend-color;
  font-size: 18px;
  line-height: 24px;
  color: $extend-color;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  margin-bottom: 2rem;
}

.engage-page {
  h4 span {
    z-index: 99999;
    padding: 0 20px;
    margin-top: 10px;
    position: relative;
    top: 10px;
    background: #fff2d3;
  }
}

.explore-page {
  h4 span {
    z-index: 99999;
    padding: 0 20px;
    margin-top: 10px;
    position: relative;
    top: 10px;
    background: #e9faef;
  }
}

.level-tab {
  .explore-main-container {
    border: 0px solid transparent;
    margin: 0 auto;
  }

  .ant-tabs-content-holder {
    // margin-left: 5rem;
    // margin-right: 5rem;
    background: #fff;
    // padding: 3rem;
    // border: 1px solid #000000;
    border-radius: 4px;
  }

  .teacher-side {
    .ant-tabs-tab {
      padding: 5px 15px;
      color: $explore-color;
      border: 1px solid $explore-color;
      background: #fff;
      border-radius: 9.88337px 9.88337px 0px 0px;
      border-bottom: none;
    }

    .ant-tabs-tab-active {
      background: $explore-color;
      border-radius: 9.88337px 9.88337px 0px 0px;
      color: #fff;
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: #fff;
    }

    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
    .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
      position: relative;
      display: block;
      transition: transform 0.3s;
      align-items: left;
      text-align: left;
      background: transparent !important;
    }
  }

  .ant-tabs-tab-active {
    background: $extend-color;
    border: 1px solid #000000;
    border-radius: 4px 4px 0px 0px;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #fff;
  }
}

.rwt__tablist[aria-orientation="vertical"]
  .rwt__tab[aria-selected="true"]:after {
  left: -1px;
  top: 0;
  height: 100%;
  border-right: 3px solid #00d8ff;
}

.custom-radio-btn {
  input[type="radio"],
  input[type="checkbox"] {
    box-sizing: border-box;
    padding: 0;
    display: none;
  }
}

.ant-select-selection-placeholder {
  flex: 1 1;
  overflow: hidden;
  color: #221f1f;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}

.explore-page {
  .filters {
    background: #ffffff;
    border: 1px solid #a2a2a2;
    border-radius: 4px;
    width: 100%;
    padding: 1.5rem;
    /* width: 200px; */
    display: flex;
    align-items: center;
    // margin-left: 15px;
    // margin-right: 10px;
  }

  .walk-color {
    width: fit-content;
    padding: 3px 6px;
    border-radius: 5px;
    background: #9cbdef;
    left: -5px;
    top: 0px;
  }

  .skate-color {
    width: fit-content;
    padding: 3px 6px;
    border-radius: 5px;
    background: #ffb6a6;
    position: relative;
    top: 0px;
    left: -5px;
  }

  .bike-color {
    width: fit-content;
    padding: 3px 6px;
    border-radius: 5px;
    background: #b3877d;
    position: relative;
    top: 0px;
    left: -5px;
  }

  .jog-color {
    width: fit-content;
    padding: 3px 6px;
    border-radius: 5px;
    background: #bb81cc;
    position: relative;
    top: 0px;
    left: -5px;
  }

  .june-color {
    width: fit-content;
    padding: 3px 6px;
    border-radius: 5px;
    background: #3e68a7;
    position: relative;
    top: 0px;
    left: -5px;
  }

  .july-color {
    width: fit-content;
    padding: 3px 6px;
    border-radius: 5px;
    background: #86b389;
    position: relative;
    top: 0px;
    left: -5px;
  }

  .september-color {
    width: fit-content;
    padding: 3px 6px;
    border-radius: 5px;
    background: #ffb6a6;
    position: relative;
    top: 0px;
    left: -5px;
  }

  .october-color {
    width: fit-content;
    padding: 3px 6px;
    border-radius: 5px;
    background: #b3877d;
    position: relative;
    top: 0px;
    left: -5px;
  }

  .november-color {
    width: fit-content;
    padding: 3px 6px;
    border-radius: 5px;
    background: #77d5d8;
    position: relative;
    top: 0px;
    left: -5px;
  }

  .december-color {
    width: fit-content;
    padding: 3px 6px;
    border-radius: 5px;
    background: #a9b55a;
    position: relative;
    top: 0px;
    left: -5px;
  }

  .august-color {
    width: fit-content;
    padding: 3px 6px;
    border-radius: 5px;
    background: #bb81cc;
    position: relative;
    top: 0px;
    left: -5px;
  }

  .rwt__tab {
    text-align: left !important;
  }

  .rwt__tablist[aria-orientation="vertical"]
    .rwt__tab[aria-selected="true"]:after {
    left: -1px;
    top: 0;
    height: 100%;
    border-right: 3px solid #00d8ff;
  }

  .rwt__tab[aria-selected="true"] {
    position: relative;
    font-weight: 700;
  }

  .mr10 {
    margin-right: 10px;
  }

  .mb20 {
    margin-bottom: 20px;
  }

  .ant-table-cell {
    .badge-secondary {
      float: right;
    }
  }

  .run-color {
    width: fit-content;
    padding: 3px 6px;
    border-radius: 5px;
    background: #86b389;
    left: -5px;
    top: 0px;
  }

  .inner-tab {
    position: relative;
    //top: -38px;
  }
}

.distance {
  writing-mode: vertical-lr;
  position: absolute;
  height: 300px;
  text-align: center;
  left: -10px;
  transform: rotate(180deg);
}
.distance-evaluate {
  writing-mode: vertical-lr;
  position: absolute;
  height: 300px;
  text-align: center;
  left: -10px;
  transform: rotate(90deg);
}

.distance-evaluate-stu {
  writing-mode: vertical-lr;
  position: absolute;
  height: 300px;
  text-align: center;
  left: -10px;
  transform: rotate(0deg);
}

.distance-explain {
  writing-mode: vertical-lr;
  position: absolute;
  height: 300px;
  text-align: center;
  left: -10px;
  transform: rotate(90deg);
}

.distance-star-path {
  writing-mode: vertical-lr;
  position: absolute;
  height: 420px;
  text-align: center;
  left: -10px;
  transform: rotate(180deg);
}

.distance-star-path-evaluate-tab {
  writing-mode: vertical-lr;
  position: absolute;
  height: 170px;
  text-align: center;
  left: -10px;
  transform: rotate(0deg);
}

.distance-earthquack-evaluate-tab {
  writing-mode: vertical-lr;
  position: absolute;
  height: 288px;
  text-align: center;
  left: 30px;
  transform: rotate(90deg);
}

.rwt__tabpanel {
  width: 100% !important;

  h2 {
    font-size: 1.6em;
  }
}

.mr10 {
  margin-right: 10px;
}

.rwt__tab {
  text-align: left;
}

.admin-table {
  background: #fafafa;
  width: 100%;
  word-break: break-all !important;
}

.rwt__tab[aria-selected="true"] {
  position: relative;
  font-weight: 700;
}

.ant-checkbox {
  input[type="radio"],
  input[type="checkbox"] {
    position: absolute;
  }
}

.ant-transfer-list {
  width: 50%;
  height: 400px;
}

.search-drop {
  width: 100%;
}

// .ant-select:not(.ant-select-customize-input) .ant-select-selector {
//   border: 1px solid #262626 !important;
//   border-radius: 1px;
// }

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  width: 100%;
  background: #ffffff;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 400;
  background-color: #fff;
}

.medium {
  .ant-drawer-content-wrapper {
    width: 40% !important;
  }
}

.Layout_content__12YGp {
  position: relative;
  flex-grow: 1;
  background-color: #fff;
  padding: 6rem 1rem;
}

.admin-nav {
  .Header_root__3qnoC {
    background: #fff !important;
  }

  .navbar {
    background: #fff !important;
  }

  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    position: relative;
    padding: 12px 12px;
    overflow-wrap: break-word;
  }

  .rwt__tablist[aria-orientation="vertical"] {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    flex-grow: 0;
    border-right: 1px solid #ddd;
    margin-right: 1rem;
    margin-top: 20px;
  }

  .btn-hide {
    display: none;
  }
 
}

.se-video-container {
  figure {
    width:650px!important;
  height:380px!important;
  padding-bottom: 0!important;
  }
 
}

.sun-editor-editable .se-component>figure {
  margin: unset!important;
}

.action {
  width: 150px;
}

.lesson-action {
  width: 300px;
}

.grey-back {
  background: #f5f5f5;
}

.f15 {
  font-size: 15px !important;
}

.update {
  margin-top: -35px;
}

.mb50 {
  margin-top: -50px;
}

.mb-150 {
  margin-bottom: 100px;
}

.btn {
  border-radius: 4px;
}

.m5 {
  position: relative;
  top: 4px;
  left: 10px;
}

.bg-evaluate {
  margin-top: -50px;
}

.explore-page .rwt__tab {
  position: relative;
  font-weight: 700;
  padding: 0;
  margin-top: 15px;
  height: 50px;
}

.rotate-text {
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.rwt__tab:focus:not(:focus-visible) {
  outline: 0 !important;
  background: #fff;
}

.top20 {
  margin-top: 15px;
}

.xy-table {
  .col-md-3 {
    padding: 2px;
  }

  .fa {
    font-size: 18px;
  }
}

.class-card {
  height: 250px;
  border-radius: 7px;
}

.badge {
  cursor: pointer;
}

#example {
  position: relative;
  top: 5px;
}

.update {
  margin-left: 20px;
}

.breadcrumb {
  margin-bottom: 10px;

  .active {
    background: transparent !important;
  }
}

.bm-item-list {
  li {
    list-style-type: none;
    margin-bottom: 15px;
  }
}

.f10 {
  font-size: 10px !important;
}

.login-bg {
  margin: 0 auto;
}

.mb-15 {
  margin-bottom: 15px;
}

.explore-page .card-chart {
  height: 78vh;
  border: 0;
}

@media only screen and (max-width: 1000px) {
  .tab-hide {
    display: none;
  }

  .navbar {
    background: #fff !important;
  }

  .logo {
    width: 180px;
  }

  .course-card {
    margin-bottom: 15px;
  }

  .lab-logo {
    width: 180px;
  }

  .home-icon {
    right: 95px !important;
  }

  .back-icon {
    right: 130px !important;
  }

  .fixed-ltab .ant-tabs-content-holder {
    margin-top: 0 !important;
  }

  .fixed-ltab .ant-tabs > .ant-tabs-nav,
  .fixed-ltab .ant-tabs > div > .ant-tabs-nav {
    position: relative;
    margin-top: 75px;
  }

  .engage-page {
    border: 5px solid $engage-color !important;
    border-radius: 4px;
  }

  .ml-5,
  .mx-5 {
    margin-left: 0 !important;
  }

  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0 !important;
  }

  .ltab .ant-tabs-tab {
    padding: 15px !important;
  }

  .lab-nav {
    background: transparent !important;
  }
}

@media only screen and (max-width: 600px) {
  .ltab .ant-tabs-tab {
    padding: 5px !important;
  }

  .lab-logo {
    width: 150px;
  }

  .order2 {
    order: 2;
  }

  .course-card {
    margin-bottom: 15px;
  }

  .qimage {
    margin-bottom: 10px;
  }

  .tab-style h3 {
    line-height: 40px;
  }

  .explore-page .card-chart {
    height: 100vh;
  }

  .experiment-dropdown {
    float: left;
  }

  .lab-nav {
    background: transparent !important;
  }
}

// .button-styling-hidden {
//   zoom: 150%;
// }


.float-container {
  z-index: 9999999999999;
}

.content .explore-page {
  background-color: #fbfbfb;
}

.content .extend-page {
  background-color: #fbfbfb;
}

.blur {
  filter: blur(3px);
  pointer-events: none;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.ant-transfer-list-header-dropdown {
  display: none;
}

.ant-list-item {
  .row {
    min-width: 100%;
  }
}

.unmarked-answer {
  img {
    position: relative;
    margin-right: 10px;
    margin-left: 10px;
  }
}

.w80width {
  width: 50%;
}

.half-drop {
  width: 50%;
}

/*.evaluate-page {
  .distance {
    top: calc(100% - 70%);
  }
}

.explain-page {
  .distance {
    top: calc(100% - 70%);
  }
}
*/
.no-padding {
  padding: 0 !important;
}

.evaluate-page {
  .filters {
    background: #ffffff;
    border: 1px solid #a2a2a2;
    border-radius: 4px;
    width: 100%;
    padding: 1rem;

    overflow-x: auto;
  }

  h4 span {
    z-index: 99999;
    padding: 0 20px;
    margin-top: 10px;
    position: relative;
    top: 10px;
    background: #ffede9;
  }
}

.explain-page {
  .filters {
    background: #ffffff;
    border: 1px solid #a2a2a2;
    border-radius: 4px;
    width: 100%;
    padding: 1rem;

    overflow-x: auto;
  }
}

.chart {
  height: 300px;
}

.success-answer {
  img {
    margin-right: 10px;
  }
}

.ex1 {
  background: #9f80e1;
  width: fit-content;
  padding: 3px 6px;
  border-radius: 5px;
  position: relative;
  top: 0px;
  left: -5px;
}

.ex2 {
  background: #9cc9e8;
  width: fit-content;
  padding: 3px 6px;
  border-radius: 5px;
  position: relative;
  top: 0px;
  left: -5px;
}

.ex3 {
  background: #9f8aa7;
  width: fit-content;
  padding: 3px 6px;
  border-radius: 5px;
  position: relative;
  top: 0px;
  left: -5px;
}

.ex4 {
  background: #80a6ab;
  width: fit-content;
  padding: 3px 6px;
  border-radius: 5px;
  position: relative;
  top: 0px;
  left: -5px;
}

.ex5 {
  background: #6d7e26;
  width: fit-content;
  padding: 3px 6px;
  border-radius: 5px;
  position: relative;
  top: 0px;
  left: -5px;
}

.ex6 {
  background: #b9eea6;
  width: fit-content;
  padding: 3px 6px;
  border-radius: 5px;
  position: relative;
  top: 0px;
  left: -5px;
}

.ex7 {
  background: #ff94da;
  width: fit-content;
  padding: 3px 6px;
  border-radius: 5px;
  position: relative;
  top: 0px;
  left: -5px;
}

.ex8 {
  background: #b1ffe1;
  width: fit-content;
  padding: 3px 6px;
  border-radius: 5px;
  position: relative;
  top: 0px;
  left: -5px;
}

.ex9 {
  background: #f4ffa5;
  width: fit-content;
  padding: 3px 6px;
  border-radius: 5px;
  position: relative;
  top: 0px;
  left: -5px;
}

.ex10 {
  background: #8fb3d6;
  width: fit-content;
  padding: 3px 6px;
  border-radius: 5px;
  position: relative;
  top: 0px;
  left: -5px;
}

.ex11 {
  background: #f5b080;
  width: fit-content;
  padding: 3px 6px;
  border-radius: 5px;
  position: relative;
  top: 0px;
  left: -5px;
}

.ex12 {
  background: #df8080;
  width: fit-content;
  padding: 3px 6px;
  border-radius: 5px;
  position: relative;
  top: 0px;
  left: -5px;
}

.ex {
  width: fit-content;
  padding: 3px 6px;
  border-radius: 5px;
  position: relative;
  top: 0px;
  left: -5px;
  display: flex;
  word-break: keep-all;
  white-space: nowrap;
}

.hypo {
  fieldset {
    border: 1px solid$explore-color;
    background: #eee;
    border-radius: 5px;
    padding: 0px 15px;
    width: fit-content;
  }

  fieldset legend {
    background: $explore-color;
    width: 200px;
    color: #fff;
    padding: 2px 10px;
    font-size: 14px;
    border-radius: 5px;

    margin-left: 20px;

    strong {
      position: relative;
      top: -2px;
    }
  }

  .hype-text {
    position: relative;
    margin-bottom: 15px;
    line-height: 1.5rem;
  }
}

.hypoe {
  cursor: default;

  fieldset {
    border: 1px solid $explain-color;
    background: #eee;
    border-radius: 5px;
    padding: 0px 15px;
    width: fit-content;
  }

  fieldset legend {
    background: $explain-color;
    width: 200px;
    color: #fff;
    padding: 2px 10px;
    font-size: 14px;
    border-radius: 5px;

    margin-left: 20px;

    strong {
      position: relative;
      top: -2px;
    }
  }

  .hype-text {
    position: relative;
    line-height: 1.5rem;
    margin-bottom: 15px;
  }
}

.hypoex {
  cursor: default;

  fieldset {
    border: 1px solid $evaluate-color;
    background: #eee;
    border-radius: 5px;
    padding: 0px 15px;
    width: fit-content;
  }

  fieldset legend {
    background: $evaluate-color;
    width: 200px;
    color: #fff;
    padding: 2px 10px;
    font-size: 14px;
    border-radius: 5px;
    margin-left: 20px;
  }

  .hype-text {
    position: relative;
    line-height: 1.5rem;
    margin-bottom: 15px;
  }
}

.no-link {
  .home-icon {
    display: none !important;
  }
}

.eve-text {
  background-color: #ffbdb0;
  color: #1c1a1a;
  display: inherit;
  flex-wrap: nowrap;
}

.btn-warning.disabled,
.btn-warning:disabled {
  color: #fff;
  background-color: #c36d8a;
  border-color: #c36d8a;
}

.btn-success.disabled,
.btn-success:disabled {
  color: #fff;
  background-color: #74a79d !important;
  border-color: #74a79d !important;
}

.bg-evaluate {
  h5 {
    padding-top: 0 !important;
  }
}

button:focus:not(:focus-visible) {
  box-shadow: none !important;
}

.mr10 {
  margin-right: 10px;
}

.ant-badge {
  box-sizing: border-box;
  margin: 0;
  padding: 5px;
  border-radius: 3px;
  color: #fff !important;
}

.mtl20 {
  position: relative;
  top: 40px;
  z-index: 99;
}

.ant-modal-body {
  width: 90%;
}

.flab {
  img {
    width: 60px;
  }
}

.green {
  background: #287767;
  padding: 5px;
  border-radius: 5px;
  color: #fff;
  position: relative;
  display: table;
}

.initial {
  display: table;
}

.red {
  background: #db2c66;
  padding: 5px;
  border-radius: 5px;
  color: #fff;
  position: relative;
  display: table;
}

.step-button {
  padding: 15px;
  display: none !important;
}

.ant-badge {
  cursor: pointer;
}

.teacher-link {
  color: $explain-color;
}

.teacher-link:hover {
  color: $explain-color;
}

.teacher-badge {
  background-color: rgba($color: $explain-color, $alpha: 0.1);
  padding: 5px 15px;
  border-radius: 5px;
  cursor: pointer;
}

.ltab {
  .ant-list-item {
    display: block !important;
  }
}

.hidden {
  display: none;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  background: #262626 !important;
  color: #fff !important;
}

.btn-primary:not(:disabled):not(.disabled):focus,
.btn-primary:not(:disabled):not(.disabled).focus,
.show > .btn-primary.dropdown-toggle {
  background: #262626 !important;
  color: #fff !important;
}

.ev-draw {
  .ant-drawer-body {
    overflow: hidden;
  }

  .scroll-tab {
    height: calc(74vh - 50px);
    overflow: hidden;
    overflow-y: scroll;
  }
}

.ant-carousel .slick-prev,
.ant-carousel .slick-next {
  color: unset;
  font-size: unset;
}

.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover,
.ant-carousel .slick-prev:focus,
.ant-carousel .slick-next:focus {
  color: unset;
}

.block {
  display: inline-block;
  position: relative !important;

  --line-color: #eee;
  --line-thickness: 1px;
  --minor-length: 17.5px;
  --major-length: 175px;

  --line: var(--line-color) 0 var(--line-thickness);
  --small-body: transparent var(--line-thickness) var(--minor-length);
  --large-body: transparent var(--line-thickness) var(--major-length);

  --small-squares: repeating-linear-gradient(
      to bottom,
      var(--line),
      var(--small-body)
    ),
    repeating-linear-gradient(to right, var(--line), var(--small-body));

  --large-squares: repeating-linear-gradient(
      to bottom,
      var(--line),
      var(--large-body)
    ),
    repeating-linear-gradient(to right, var(--line), var(--large-body));

  margin: 0;
  padding: 0;
  height: 100%;
  background-image: var(--small-squares), var(--large-squares);
}

.course-card {
  margin-top: 10px;
}

.tch {
  text-align: left;
}

.ant-popover-message {
  .anticon {
    position: relative;
    // top: -5px;
  }
}

.ant-icon-up {
  position: relative;
  top: -4px;
}

.new-notification-icon {
  .ant-notification-notice-message {
    position: relative;
    top: 5px;
  }
}

.canvas-wrap {
  display: inline-block;
  width: 540px;
  height: 400px;
  margin: 0 auto;
  background: black;
  position: relative;
}

.ant-message-notice-content {
  .anticon {
    position: relative;
    top: -4px;
  }
}

.ant-input-suffix {
  margin-left: 4px;
  position: relative;
  top: -4px;
}

.mb5 {
  margin-bottom: 5px;
}

.rdw-image-modal {
  left: auto !important;
  right: 0 !important;
}

.explain-page {
  ul,
  ol {
    margin-left: 20px;
    margin-bottom: 10px;
    margin-top: 10px;
    font-size: 18px !important;
  }
}

.extend-page {
  ul,
  ol {
    margin-left: 20px;
    margin-bottom: 10px;
    margin-top: 10px;
    font-size: 18px !important;
  }
}

.explore-page {
  .card-body {
    min-height: auto !important;
  }
}

.f18 {
  font-size: 18px !important;
}

.ant-table-cell {
  max-width: 75vh !important;
  min-width: 100px;
  word-break: break-all;
}

.ant-table-thead > tr > th {
  word-break: normal !important;
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #3b3b3b !important;
  border-color: #3b3b3b !important;

  :hover {
    background-color: #3b3b3b !important;
    border-color: #3b3b3b !important;
    cursor: default;
  }
}

.block-inline {
  .ant-row {
    display: inline !important;
  }
}

.ant-notification-notice {
  width: unset;

  li {
    padding-right: 15px;
  }

  z-index: 999999999;

  .ant-notification-notice-close {
    position: absolute;
    top: 0px !important;
    right: 8px !important;
    color: rgba(0, 0, 0, 0.45);
    outline: none;
  }
}

.notification-scroller {
  height: 42vh;
  overflow-y: auto;
}

.upload-btn {
  width: 100%;
}

.upload-btn:hover {
  border-color: #000000;
}

.sun-editor .se-toolbar {
  font-family: "CarmenSans-Regular";
}

.se-toolbar-sticky {
  position: relative !important;
}

.error-msg {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #ff4b23;
}

.mt-32 {
  margin-top: 32px;
}

.mt--10 {
  position: relative;
  top: -5px;
}

.evtab {
  .ant-tabs-content-holder {
    margin-top: 0 !important;
  }
}

.full-width {
  width: 100%;
}

.rl-button {
  .ant-space-item {
    .ant-btn {
      float: right;
      position: absolute;
      right: 10px;
      bottom: 10px;
    }
  }
}

td.ant-table-column-sort {
  background: transparent;
}

/// sun Editor

.sun-editor .se-wrapper .se-wrapper-wysiwyg {
  border: 1px solid #e6e5e5;
}

.sun-editor {
  border: none !important;
}

.sun-editor .se-wrapper .se-wrapper-wysiwyg {
  font-family: "CarmenSans-Regular";
}

.sun-editor-editable {
  font-size: 18px;
}

.se-navigation {
  display: none;
}

.sun-editor .se-list-layer.se-list-font-size {
  min-width: 140px;
  max-height: 150px;
}

.sun-editor .se-list-layer.se-list-format {
  min-width: 156px;
  max-height: 200px;
  height: 150px !important;
}
.sun-editor .se-resizing-bar {
  border-top: none !important;
}

div[contenteditable="false"] {
  background: #e9ecef;
}

.black-link {
  color: #000;
}

.b-arrow {
  font-size: 13px;
}

.react-confirm-alert-body > h1 {
  font-size: 18px;
}

.bg-engage {
  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  table td {
    padding: 15px;
  }
  table tr th {
    background-color: #54585d;
    color: #ffffff;
    font-weight: bold;
    // font-size: 13px;
    border: 2px solid #979a9e;
    padding: 15px;
  }

  // table tr:first-child td {
  //   background-color: #54585d;
  //   color: #ffffff;
  //   font-weight: bold;
  //   font-size: 13px;
  //   border: 1px solid #979a9e;
  // }

  table tbody td {
    color: #636363;
    border: 2px solid #dddfe1;
  }

  table tbody tr {
    background-color: #f9fafb36;
  }

  table tbody tr:nth-child(odd) {
    background-color: #ffffff36;
  }
}

.guidelines-description-table {
  overflow-x: auto;
  overflow-y: hidden;
  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

  table td {
    padding: 15px;
  }

  table tr th {
    background-color: #54585d;
    color: #ffffff;
    font-weight: bold;
    // font-size: 13px;
    border: 2px solid #979a9e;
    padding: 15px;
  }

  // table tr:first-child td {
  //   background-color: #54585d;
  //   color: #ffffff;
  //   font-weight: bold;
  //   font-size: 13px;
  //   border: 1px solid #979a9e;
  // }

  table tbody td {
    color: #636363;
    border: 2px solid #dddfe1;
    // font-size: 13px;
  }

  table tbody tr {
    background-color: #f9fafb36;
  }

  table tbody tr:nth-child(odd) {
    background-color: #ffffff36;
  }
}

div[contenteditable="false"] {
  background: transparent !important;
}

.ant-select-item-option-disabled {
  color: #000;
}

.mt--25 {
  margin-top: 25px;
}

.mrl-25 {
  margin-left: 25px;
  margin-right: 25px;
}

.mb-251 {
  position: relative;
  top: -50px;
}

.close-hide {
  display: none;
}

.fixed-width-115 {
  min-width: 115px;
}

.click {
  cursor: pointer;
}

.m10 {
  margin-top: 10px;
}

#react-confirm-alert {
  position: relative;
  z-index: 2147483659 !important;
}

// .ant-select-selection-item {
//   color: #6b859e;
// }

.back-btn-hover:hover {
  color: #00aeef;
}

// ant-carousel style
.ant-carousel-style {
  position: relative;
  z-index: 999;
}

.explore-measure-img-canvas-new {
  position: relative;
  z-index: 20;
  width: 540px;
  height: 400px;
}

.ant-spin-nested-loading > div > .ant-spin {
  // width: 100%;
  height: 100vh;
  position: fixed !important;
  max-height: fit-content;
  z-index: 999999999999999999999999 !important;
  background: linear-gradient(to bottom, #ffffff00 100px, #0f8afe20);
  /* For modern browsers */
  background: -webkit-linear-gradient(top, #ffffff00 100px, #0f8afe20);
  /* Fallback for old browsers */
  background: -moz-linear-gradient(top, #ffffff00 100px, #0f8afe20);
  /* Fallback for Internet Explorer 10 and below */
  background: -ms-linear-gradient(top, #ffffff00 100px, #0f8afe20);
}

.ant-spin-nested-loading > div > .ant-spin .ant-spin-text {
  position: absolute;
  // top: 22%;
  // width: 50%;
  // padding-top: 5px;
  text-shadow: none !important;
  text-align: center;
}

.ant-spin-nested-loading > div > .ant-spin .ant-spin-dot {
  position: fixed;
}

.spinner-new {
  .ant-spin-text {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 14px;
    // width: 100vw;
    margin-top: 35px;
    width: unset !important;
  }
}

///////// Form Feedback //////////
.override-FormFeedback.invalid-feedback,
.override-FormFeedback .invalid-feedback {
  display: block !important;
}

.ant-form .ant-form-horizontal {
  .upload {
    width: 100%;
  }
}

// .ant-drawer-body {
//   input {
//     border: 1px solid black;
//     border-radius: 0;
//   }
// }

.form-control {
  border: 1px solid #e6e5e5 !important;
  border-radius: 0% !important;
}
.flex-rows {
  .ant-form-item-row {
    display: block !important;
  }
}

.language {
  background: #3a3a3a;

  select {
    background: #3a3a3a;
    color: #fff;
    padding: 5px;
    width: 100%;
    border-radius: 4px;
    font-size: 12px;
  }
}
.no-click {
  cursor: default;
}
.no-click:hover {
  color: #eee;
}

.f18.middle * {
  text-align: center !important;
}
center {
  text-align: center !important;
}
.mt-25 {
  margin-top: 25px;
}
.button-set {
  width: 230px;
}

.score {
  display: none;
}
.score-hover:hover {
  .score {
    display: block;
  }
}
.f14 {
  font-size: 14px;
}
.g-card-title {
  word-wrap: break-word;
}

.explore-page {
  .filters {
    .ant-radio-group {
      display: flex;
    }
  }
}
.evaluate-page {
  .filters {
    .ant-radio-group {
      margin-left: 15px;
    }
    .ant-radio-wrapper {
      margin-top: 5px;
    }
  }
}
.explain-page {
  .filters {
    .ant-radio-group {
      margin-left: 15px;
    }
    .ant-radio-wrapper {
      margin-top: 5px;
    }
  }
}
.min-w {
  min-width: 120px;
}

.dlabel {
  vertical-align: top !important;
}
body {
  top: 0 !important;
}
.pdf-text {
  vertical-align: text-bottom;
  margin-left: 10px;
}
#goog-gt-tt {
  display: none !important;
}
#goog-gt-tt:hover {
  display: none !important;
  visibility: initial;
  left: 412px;
  top: 167px;
  width: 536px;
  display: none;
}

#goog-gt-vt {
  display: none;
}
.center-btn {
  text-align: center;
}
font {
  background-color: transparent !important;
  box-shadow: none !important;
}
.wi20 {
  width: 60px;
  appearance: auto;
  inline-size: 50px;
  block-size: 27px;
  cursor: default;
  box-sizing: border-box;
  background-color: buttonface;
  color: buttontext;
  border-width: 1px;
  border-style: solid;
  border-color: buttonborder;
  border-image: initial;
  padding: 1px 2px;
  height: 27px !important;
}
.mtop43 {
  margin-top: 5px;
}
.flexr {
  .ant-form-item-row {
    display: flex !important;
  }
  margin-bottom: 0;
}
.mt20 {
  margin-top: 20px;
}
.hei15 {
  height: 45px;
  margin-top: 10px;
}

// lessonplan tab player
.lesson-plan {
  .t-tabs .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
  .t-tabs .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
    text-align: left !important;
  }
  .t-tabs .ant-tabs-tab {
    padding: 5px;
  }
}

.btn-blue {
  background: $explain-color !important;
  border-color: $explain-color !important;
  align-items: center;
  padding: 8px 32px;
  border-radius: 1px;
  color: #fff;
}

.btn-blue:hover {
  border-color: $explain-color !important;
  background: #fff !important;
  color: $explain-color;
}
.ml80 {
  margin-left: 80px;
}
.expand-tab-back {
  .ml80 {
    margin-left: 50px;
  }
}

ol {
  list-style: decimal !important;
  padding-left: 20px !important;
}
ul {
  list-style: disc !important;
  padding-left: 20px !important;
}
.__se__float-right {
  display: flex;
  justify-content: right;

  figure {
    margin: initial !important;
  }
}
.__se__float-left {
  text-align: left;

  figure {
    margin: initial !important;
  }
}
.__se__float-center {
  text-align: center;
  display: flex;
  justify-content: center;
}
.cl-toggle {
  text-align: right;
  position: relative;
  top: -55px;
  padding-right: 15px;
}
.ant-switch {
  background-color: #b0a5a5;
}
.ant-switch-checked {
  background-color: #1890ff;
}
.cl-alert {
  position: fixed;
  bottom: 30px;
  width: 350px;
  float: right;
  right: 30px;
}

.new-alert {
  // height: 70px;
  display: flex;
  flex-direction: row;
  position: fixed;
  background-color: $bg-light;
  width: 100vw;
  bottom: 0;
  left: 0;
  z-index: 1;
  padding-top: 1.5rem;
  padding-bottom: 1.3rem;
  justify-content: space-around;
  // box-shadow: 0px -10px 20px rgba(0, 0, 0, 0.1);
  border-top: 2px solid rgba(0, 0, 0, 0.1);
  .alert-btn-container {
    display: flex;
    flex-direction: row;
  }
  Button {
    width: 70px;
  }
}

@media (max-width: 495px) {
  .alert-btn-container {
    flex-direction: column !important;
    // align-items: flex-end;
    Button {
      margin-bottom: 5px;
    }
  }
}
@media (max-width: 780px) {
  .new-alert {
    div {
      p {
        padding-left: 10px;
      }
    }
  }
}

img {
  max-width: 100%;
}
.sun-editor-editable p {
  line-height: normal !important;
}

.finish {
  table,
  td,
  th {
    border: 1px solid #ddd;
    text-align: left;
  }

  table {
    border-collapse: collapse;
    width: 100%;
  }

  th,
  td {
    padding: 15px;
  }
}
.ant-transfer-list-content-item-text {
  white-space: normal !important;
}
.mt--15 {
  margin-top: -15px;
}
label {
  font-size: 14px !important;
}
.invalid-feedback {
  font-size: 13px !important;
}

@media (max-width: 1471px) {
  .explore-measure-img-canvas {
    width: 100% !important;
  }
}

.ant-select-dropdown-placement-topRight {
  top: 43.62px !important;
  position: relative;
}

.admin-tabs {
  margin-left: 200px;
  width: calc(100vw - 250px);
  min-height: calc(100vh - 7.5rem);
}
.tablist-fixed {
  height: calc(100vh - 7.5rem);
  position: fixed;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 1;
  // direction: rtl;
}

.ant-upload-text-icon {
  margin-top: -6px;
}
.duplicate {
  .ant-transfer-list {
    height: 550px;
  }
}
.mr10 {
  margin-left: 10px;
}
.error-msg {
  margin-top: 0.25rem;
  font-size: 13px;
  color: #ff4b23;
}
.ant-form-item-explain-error {
  font-size: 13px !important;
}

.blured-content {
  background-color: rgba(255, 255, 255, 0.95);
  height: 200%;
  width: 100%;
  display: flex;
  position: absolute;
  z-index: 2;
  // filter: blur(10px);
}

.dflexc {
  display: flex;
  width: 100%;
}

.apexcharts-tooltip {
  background: #f0f0f0;
  border-radius: 10px; /* Rounded corners */
  margin: auto; /* Center the container */
}
.apexcharts-tooltip span {
  padding: 5px;
  display: inline-block;
  font-size: 12px; /* Smaller text size for labels */
  color: #333; /* Dark grey color for text */
}

.logout {
  display: block;
  position: absolute;
  width: 90%;
  bottom: 70px;
}
.se-video-container {
iframe {
  width: 640px!important; /* Example width */
  height: calc(640px * 9 / 16)!important; /* Calculate height based on width */
  
  border: none!important;
  figure {
    padding-bottom:0!important;
  }
}
.se-btn {
  display: none!important;
}
.se-dialog-form {
  display: none;
}
}
.__se__float-left figure {
  padding-bottom:0!important;
}
figure {
  padding-bottom:0!important; 
  margin-bottom: 20px;
    margin-top: 20px;
}
._se_resizing_btn_group {
  display: none!important; 
}
.teacher-detail {
.se-video-container {
  margin-bottom: 80px;
}

}
.override-FormFeedback {
  .se-video-container {
    margin-bottom: 80px;
  }
}

  .sun-editor .se-dialog .se-dialog-inner .se-dialog-content .se-dialog-footer label {
    display: none!important;
  }


//Mac issue

// .fixed-md {
//   width: 560px;
//   max-width: 560px;
// }
// @media only screen and (min-device-width: 1330px) and (max-device-width: 1500px)  {
//   .fixed-md {
//     width: 580px;
//     max-width: 580px;
//   }
// .dtable {
//   max-width: 300px;
// }
// .chdiv {
//   max-width: 31.66667%!important;
// }
// }

.tdiv {
  width: 350px ;
  height: 60px;
  bottom: 5px;
  position: absolute;
  right: 10px;
}

.tdivsm {
  width: 300px ;
  height: 60px;
  bottom: 5px;
  position: absolute;
  right: 10px;
}

// .anticon-close {
//   top: 4px;
//     position: relative;
// }
.explain-pdf-tour{
  height: 80px;
    position: absolute;
    width: 250px;
    margin-left: 60px;
    bottom: 60px;
}


.skiptranslate{
  iframe{
    display: none !important;
  }
}


.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after{
  border-color:white
}