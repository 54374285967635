.float-container{
    position: fixed;
    bottom: calc(7%);
    right: calc(5%);
}
.wrapper{
	position:relative;
	display:flex;
	justify-content:center;
	align-items:center;
	flex-direction:row-reverse ;
}

.hidden{
	transform:scale(0);
	transform-origin:bottom;
}

.top{
	z-index:1000;
}

.rotate{
	transform:rotate(45deg);
}

.button-styling-display{
    color: #fff;
    font-weight: 400;
    background-color: #495057;
    border-color: transparent;
    margin: 5px;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
    box-shadow: 0px 3px 9px rgba(0,0,0,.2);
}

.button-styling-hidden{
    border-radius: 50%;
    padding: 15px 15px 15px 15px;
    margin: 5px;
    color: #fff;
    background-color: #495057;
    cursor: pointer;
    box-shadow: 0px 3px 9px rgba(0,0,0,.2);
    display: flex;
    align-content: center;
}

.button-styling-disable{
    color: #fff;
    font-weight: 400;
    background-color: #495057;
    border-color: transparent;
    margin: 5px;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
    box-shadow: 0px 3px 9px rgba(0,0,0,.2);
    pointer-events: none;
    opacity: 0.5;
}