.teacher-evaluates-answer{
    background-color: #EEC373;
    color: #876445;
    h5{
    color: #876445;
    }
    hr{
      margin-top: 1rem;
      margin-bottom: 1rem;
      border: 0;
      border-top: 1px solid #876445 !important;  
    }
  }