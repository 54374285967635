@import "../../styles/helpers";

.footer {
  position: relative;
  right: 24px;
  color: black;
  font-size: 10px;
  padding-top: 50px;
  padding-bottom: 50px;
}

.footerLabel {
  margin-right: 4px;
}

@media only screen and (max-width: 1000px) {
  
  .footer {
    
    right: 0px!important;
    
  }
  }
