$engage-color:#feb30f;
$explore-color:#37b14a;
$evaluate-color:#fa5b38;
$explain-color:#0f8bfe;
$extend-color:#262626;
$text-color-light:#ffffff;

$text-color-dark:$extend-color;
$visited-color:$explain-color;
$unvisited-color:$text-color-light;

$bg-light:$text-color-light