
.notification-container {
    background-color: rgba(255, 255, 255, 0.1);
    color: #fff;
    font-size: 14px;
    padding: 10px;
    margin: 10px 0;
}
.unvisited{
    border-left: 5px solid #0f8bfe ;
}

.visited{
    border-left: 5px solid #fff ;
}

.notification-container-hover {
    position: relative;
    top: 0px;
    height: 0;
}
