@import "_labVariables.scss";
$text-color:#5C5C5C;

.welcome-container{
    border: 1px solid #26262633;
    width: 100%;
    padding: 1.5rem 1rem;
    border-radius: 0.5rem;
    // background-color: #26262603;
    h6, h5{
        color: $text-color;
    }
}
.apexcharts-subtitle-text{
    display: none;
}
.stat-container{
    border: 1px solid #26262633;
    width: 100%;
    padding: 1rem 1rem;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    h6, h5,.stat-text{
        color: $text-color;
    }
    h5{
        padding-top: 0.5rem;
    }
    .stat-quantity{
        color: $text-color;
        font-size: medium;
    }
    .stat{
        font-size: x-small;
        color: $text-color;
    }
    .stat-count{
        font-size: medium;
    }
    .stat-increase{
        background-color: #37b14916;
        color: #37b14a;
        padding: 5px 10px;
        border-radius: 5px;
        margin-right: 5px;
    }
    .stat-decrease{
        background-color: #fa5b3816;
        color: #fa5b38;
        padding: 5px 10px;
        border-radius: 5px;
        margin-right: 5px;
    }

    .stat-equal{
        background-color: #716E6E16;
        color: #716E6E;
        padding: 5px 10px;
        border-radius: 5px;
        margin-right: 5px;
    }
    .stat-icon-container{
        display: flex;
        align-items: center;
        width: 80px;
        .stat-icon-wrapper{
            width: 80px;
            height: 80px;
            border-radius: 40px;
            background-color: #00AEEF1A;
            .stat-icon{
                width: 80px;
                height: 78px;
                font-size: 32px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #0f8bfe;
            }
        }
    }
}

.dashboard-title{
    h6, h5{
        color: $text-color;
    }
    h5{
        padding-top: 0.5rem;
    }

}

.filter-container{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.date-time{
    color: $text-color;
}

.ant-picker-range-separator{
    position: relative;
    // top: -6px;
}